export const jobData = [
  {
    key: "MDP",
    title: "Irish Bee Conservation Project",
    desc: "During the summer of 2023, I was brought in to redesign and redevelop the web presence for the Irish Bee Conservation project. Their current site was resulting in unfavourable User Experience outcomes and as a result, customers were not being driven to their store and donation system as much as they would like. My job was to come in and understand the company's problems, propose a technology based solution and implement that solution. I worked closely with the company's founder to understand the company's goals and vision. I then created a new website using their existing WordPress site as a base. The site was designed to be highly interactive and engaging, with a focus on driving customers to the store and donation system. The site is currently in being worked on by external backend developers but a low-bandwidth version is available below.",
    view: "http://ibcp.ie/",
    imgSrc: require("./portfolioImages/port5.png"),
    thumb: require("./portfolioImages/ibcp.webp"),
  },
  {
    key: "PHP",
    title: "PHP/MySQL + Vanilla JS Chat App",
    desc: "Simple chat application built using PHP, MySQL, and Vanilla JS. This project aimed to enhance my PHP and MySQL skills, laying a solid foundation for bigger and better projects in the future. The application's structure entailed a robust backend implemented with PHP, handling server-side logic and data manipulation. Vanilla JS, on the other hand, managed the frontend, rendering  UI components and seamlessly interacting with the backend API. Incorporating MySQL as the database provided a stable platform to store and retrieve chat messages, ensuring data integrity.",
    thumb: require("./portfolioImages/php-thumb.png"),
    video: "https://www.youtube.com/embed/dPgrmIpMd4o?si=p_lbkbwtScc8pegX",
    github: "https://github.com/KevinThePirate/PHP-MySQL-Chat-Application/tree/master",
  },
  {
    key: "SQLBlog",
    title: "Full Stack Blog with SQL",
    desc: "Recently, I created a full-stack blog application, utilizing React, Express, and SQL/MySQL. This project aimed to enhance my SQL skills, laying a solid foundation for bigger and better projects in the future. The application's structure entailed a robust backend implemented with Express, handling server-side logic and data manipulation. React, on the other hand, managed the frontend, rendering  UI components and seamlessly interacting with the backend API. Incorporating MySQL as the database provided a stable platform to store and retrieve blog posts, ensuring data integrity. Feel free to view the code below or a video showing the functionality. ",
    view: "http://ibcp.ie/",
    imgSrc: require("./portfolioImages/port5.png"),
    video: "https://www.youtube.com/embed/GYMN7THgMqU?si=-D1qKs3fymPuOzot",
    github: "https://github.com/KevinThePirate/Full-Stack-Blog-Web-App",
    thumb: require("./portfolioImages/sql-thumb.png"),
  },
  {
    key: "MDP",
    title: "Master's Dissertation Project",
    desc: "For my Master's Dissertation project, I created a productivity web app designed to help users regulate their moods and increase productivity in a flexible working environment. The app collected data on users' tasks, moods, and the times they completed those tasks. This data was used to generate personalized recommendations for improving working habits. 31 participants used the application for two working weeks and after surveying them we found that the app helped them understand their working habits and improve productivity.",
    view: "https://digital-artefact-madh.vercel.app/",
    github: "https://github.com/KevinThePirate/digital-artefact-madh",
    doc: "https://docs.google.com/document/d/1H7kebIbVhYBO6OJ6arWUDLnmq7pFSsbhIgAN--CyuMU/edit?usp=sharing",
    imgSrc: require("./portfolioImages/port3.png"),
    thumb: require("./portfolioImages/ucc-logo-cropped.png"),
  },
  {
    key: "PW",
    title: "2023 Portfolio Website",
    desc: "In 2023 I began to develop my portfolio website using React JS and various libraries like Framer Motion and Full Page JS. I initially designed the site's UI as a personal project while working for the Nimbus Research Institute. With a clear vision in mind, I aimed to create a site that showcased my diverse portfolio types. First and foremost, React JS served as the foundation of my website, enabling me to build a highly interactive and responsive user interface. Its component-based architecture facilitated modular development and enhanced code reusability, enabling me to create distinct sections for each portfolio type. To add some flair to my website, I turned to Framer Motion. This animation library allowed me to craft seamless transitions, hover effects, and page-scroll animations. Additionally, I integrated Full Page JS to introduce an intuitive navigation system, enabling users to effortlessly explore various portfolio types. This portfolio has gone on a number of iterations since then, but the core of the site remains the same.",
    github: "https://github.com/KevinThePirate/2023PortfolioSiteNext",
    figma:
      "https://www.figma.com/file/NGDHfCZ9MXvkFb36e1zk16/Kevin-Smith-Personal-Portfolio-UI?type=design&node-id=128%3A448&mode=dev",
    imgSrc: require("./portfolioImages/port2.png"),
    thumb: require("./portfolioImages/framer-react-logo-cropped.png"),
  },
  {
    key: "FYMP",
    title: "Final Year Project: Observo",
    desc: "For my final year project in Creative Digital Media, I developed a React application that aimed to explore the correlation between habits and mood. This project served as my thesis for my undergraduate degree and was the most ambitious project I have worked on to date.  Leveraging the power of React, I crafted a user-friendly interface that allowed users to log in and seamlessly track their habits and mood over time. By integrating a Firebase backend, I ensured that user data was securely stored and easily accessible. The application enabled users to input their daily habits and record their mood, and then provided visualizations and analysis to showcase any potential connections between the two. This unique approach to understanding the impact of habits on mood resonated with both users and my academic evaluators. ",
    view: "https://final-year-project-version-b.vercel.app/",
    github: "https://github.com/KevinThePirate/Final-Year-Project-Version-B",
    video: "https://www.youtube.com/embed/ApLa5r7-o3E?si=o1tGsPtN3D-r0rLH",
    figma:
      "https://www.figma.com/file/pegPaFApIKhJWfFD2H7cNh/Digital-Overlay-Prototype?type=design&node-id=0%3A1&mode=design&t=qIO3OhmZjP0PDikp-1",
    doc: "https://drive.google.com/file/d/1Vogk1cvB8PAIdQI9wWByRsZ75xfGvA12/view?usp=drive_link",
    imgSrc: require("./portfolioImages/port1.png"),
    thumb: require("./portfolioImages/observo-guy.png"),
  },
];
